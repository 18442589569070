import * as React from 'react'
import { Link } from 'gatsby'
import { Container, Button } from 'react-bootstrap'

import MainLayout from '../components/main-layout'
import HeaderBlock from '../components/header-block'


const NotFoundPage = () => {

    return (
        <MainLayout pageTitle="404">
            <Container>
                <HeaderBlock header="404">
                    <p>Page not found.</p>
                    <Link to="/" className="text-center justify-content-center"><Button>Return to Home</Button></Link>
                </HeaderBlock>

            </Container>


        </MainLayout>
    )
}


export default NotFoundPage